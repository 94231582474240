
@import '../vars';

.Root {
    background: $color-primary-foreground;
    margin:auto;
    max-width:865px;
    outline: none;
    padding:35px;
    padding-top: 5px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.TextField {
    color: black !important;
}

.Title, .Text, .ButtonGrid {
    padding: 0px 20px 0px 20px;
}

.Header {
    color: black !important;
}

.Title h2 {
    color: black;
}

.Text p {
    word-wrap: break-word;
}

.ButtonGrid Button {
    position: relative;
    float: right;
}

.FormControlLabel {
    position: relative;
    float: right;
    padding: 0px 30px 0px 30px;
}

.Layout {
    align-items: center !important;
    justify-content: center !important;
}

.Paragraph {
    padding-left: 20px;
}