.editorWrapper {
    min-height: 500px;
}

.editor {
    padding: 15px;
    min-height: inherit;
}

.GridButton {
    margin: 30px;
}