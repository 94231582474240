@import './../../../vars';

.Root {
    background: $color-primary-foreground;
    margin:auto;
    max-width:865px;
    outline: none;
    padding: 15px;
    margin-bottom: 10px;
}

.TextField {
    color: black !important;
}

.Title, .Text, .ButtonGrid {
    padding: 0px 20px 0px 20px;
}

.Title h2 {
    color: black;
}

.Text p {
    word-wrap: break-word;
}

.ButtonGrid Button {
    position: relative;
    float: right;
}

.FormControlLabel {
    position: relative;
    float: right;
    padding: 0px 30px 0px 30px;
}