.fileContainerWrapped {
    background: #fff;
    position: relative;
    border-radius: 10px;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px auto;
    transition: all 0.3s ease-in;
}

.fileContainerWrapped .chooseFileButtonWrapped {
    padding: 6px 23px;
    background: #3f4257;
    border-radius: 30px;
    color: white;
    font-weight: 300;
    font-size: 14px;
    margin: 10px 0;
    transition: all 0.2s ease-in;
    cursor: pointer;
    outline: none;
    border: none;
}

.fileContainerWrapped .chooseFileButtonWrapped:hover {
    background: #545972;
}

.fileContainerWrapped .uploadPictureContainer {
    width: 25%;
    margin: 1%;
    padding: 10px;
    background: #edf2f6;
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
    border: 1px solid #d0dbe4;
    position: relative;
}

.fileContainerWrapped .uploadPictureContainer img.uploadPicture {
    width: 100%;
}

.fileContainerWrapped .deleteImage {
    position: absolute;
    top: -9px;
    right: -9px;
    color: #fff;
    background: #ff4081;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    font-size: 26px;
    font-weight: bold;
    line-height: 30px;
    width: 30px;
    height: 30px;
}

.Root {
    z-index:10000 !important;
    padding-left: 5%;
}

.Center {
    display:flex;
    justify-content: center;
}

.Gallery {
    margin-top:2%;
}

.PhotoButtonHolder {
    margin-top: 15px;
}

.SaveButton {
    margin-left: 20px;
    margin-right: 20px;
}