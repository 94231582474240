@import '../vars';

.Modal {
    overflow: auto;
    display: flex;
    align-content: center;
}

.Root {
    background: $color-primary-foreground;
    margin:auto;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    max-width:665px;
    outline: none;
    padding: 22px;
    overflow-y: scroll;
    @media only screen and (max-width: 600px) {
        margin-left: 10px;
        margin-right: 10px;
    }
}

.buyerFormReadonly {
    margin-top: 20px;
    opacity: 0.5;
    pointer-events: none;
}