.Box {
    margin-bottom: 25px;
}

.Layout {
    align-items: center !important;
    justify-content: center !important;
}

.RootGrid {
    padding: 20px 30px 25px 30px;
}

.FormLabel {
    padding-right: 15px;
}

.ButtonGrid {
    padding-right: 15px;
}

.Button {
    padding-left: 25px;
    padding-right: 25px;
}

.Container p, h3 {
    padding-left: 25px;
}

.Header {
    color: black !important;
    padding-left: 25px;
}