.Root {
    padding: 2% 0%;
    display: flex;
    padding: 5% 0;
}

.Button {
    display: flex;
    justify-content: flex-end;
}

.Error {
    color: red;
}

.FormControl {
    width: 100%
}

.MarginTop {
    margin-top: 2%;
}

.CenterButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 1%
}

.Text {
    margin-top: 3%;
    font-weight: bold;
}

.Modal {
    overflow: auto;
    display: flex;
    align-content: center;
}

.RootModal {
    background: white;
    margin: auto;
    max-width: 665px;
    outline: none;
    padding: 22px;
}
