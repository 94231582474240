@import '../vars';

.Success {
    background-color: $color-success;
}

.Error {
    background-color: $color-error
}

.Info {
    background-color: $color-info
}

.Message {
    display: flex;
    align-items: center;
}

.Icon {
    margin-right: 8px;

}

.Root {
    div {
        z-index: 100000;
    }
}