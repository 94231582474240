.Root > div > div {
   max-width: 100%;
   width: 1280px;
}

.iframeContainer {
    height: 600px;
}

.generateButton {
    margin: 10px;
    margin-bottom: 25px;
}

.iframePlaceholder {
    background: url('./loading.svg') 0px 0px no-repeat;
    background-position: center;

}

.lastInvoice {
    display: block;
    font-weight: 700;
    font-style: italic;
}

.Modal {
    overflow: auto;
    display: flex;
    align-content: center;
}

.RootModal {
    background: white;
    margin: auto;
    max-width: 665px;
    outline: none;
    padding: 22px;
}