@import './components/vars';
$FontPathOpenSans: '../node_modules/npm-font-open-sans/fonts';
@import '../node_modules/npm-font-open-sans/open-sans';
@import "../node_modules/react-image-gallery/styles/scss/image-gallery.scss";

html,body {
    margin:0;
    padding:0;
    height: 100%;
  }

body {
    font-family: 'Open Sans';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background:$color-layout-background;
}

#root {
    height:100%;
  }

h1 {
    color: $main-heading-text;
    font-weight: 600;
    font-size: 1.5em;
    line-height: 1;
    display: block;
}

.InfoButton {
  background-color: $color-info;
}
// auti
.Available{
  background-color: $color-sell !important;
  color: white !important;
  &:hover{
      background-color: $color-success-hover;
  }
}

.Sold{
  background-color: $color-success !important;
  color: white !important;
  &:hover{
      background-color: $color-success-hover;
  }
}

// warranty statusi
.Active{
  background-color: $color-success !important;
  color: white !important;
  &:hover{
      background-color: $color-success-hover;
  }
}

.Approved{
  background-color: $color-success !important;
  color: white !important;
  &:hover{
      background-color: $color-success-hover;
  }
}

.Declined{
  background-color: $color-error !important;
  color: white !important;
  &:hover{
      background-color: $color-error-hover;
  }
}

.InProcess{
  background-color: $color-info !important;
  color: white !important;
  &:hover{
      background-color: $color-error-hover;
  }
}


.Inactive{
  background-color: $color-inactive !important;
  color: white !important;
  &:hover{
      background-color: $color-error-hover;
  }
}

.Submitted{
  background-color: $color-submitted !important;
  color: white !important;
  &:hover{
      background-color: $color-error-hover;
  }
}


.InvoiceTable .MuiTableCell-head  {
    position: inherit !important;
}


