@import '../vars';

$drawerWidth: '240px';

.Img {
    background: linear-gradient(rgba(20,20,20,0.7), rgba(20,20,20,0.7)), url(../../resources/img/nav.jpg);
    -webkit-box-shadow: 4px 1px 12px -2px rgba(0,0,0,0.75);
    -moz-box-shadow: 4px 1px 12px -2px rgba(0,0,0,0.75);
    box-shadow: 4px 1px 12px -2px rgba(0,0,0,0.75);
    background-position: center;
    background-size: cover;
}

.Content {
    padding-top: 3%;
    margin-bottom: 12px;
    width: 100%;
    @media only screen and (max-height: 1400px) {
        margin-top: 10px;
    }
    
    @media only screen and (max-width: 600px) {
        margin-top: 38px;
        margin-bottom: 30px;
        margin-left: 0px;
    }
}
.Root {
    display: flex;
    position: relative;
    height: 100%;
}

.Drawer {
    width: $drawerWidth;
    flex-shrink:0;
    white-space: nowrap;
    
    svg {
        margin-left: 7px;
        color: $color-primary-foreground;

    }
    span {
        color: $color-primary-foreground;
    }
    hr {
        background-color: $color-primary-foreground;
        opacity: 0.8;
    }
}
.Toolbar {
    display: flex;
    padding: 0 8px;
    min-height: 56px;
    align-items: center;
    justify-content: center;
}

.Home {
    svg {
        margin-left: 0px;
    }
}

.MenuBar {
    position: fixed;
    // margin-left: 106px;
    margin-top:16px;
    
    button {
        width: 41px;
        height: 41px;
        background: $color-primary-foreground;
        opacity: 0.8;
        -webkit-box-shadow: 4px 6px 20px -5px rgba(0,0,0,1);
        -moz-box-shadow: 4px 6px 20px -5px rgba(0,0,0,1);
        box-shadow: 4px 6px 20px -5px rgba(0,0,0,1);
        cursor: pointer;

        svg {
            margin-top: -3px;
        }
    }

    @media only screen and (max-width: 600px) {
        margin-left: 9px;
        margin-top:8px;
    
    }
}

.DrawerClose {
    @media only screen and (max-width: 600px) {
        display: none;
        flex-shrink: 0;
    }
}

.Logo {
    display: flex;
    align-items: center;
    height:72px;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    color: $color-primary-foreground;
}

.name {
    color: white;
    display: block;
    font-size: 12px;
    text-align: center;
    margin-top: -10px;
    margin-bottom: 5px;
}