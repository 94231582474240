.Root {
    text-align: center;
    margin: 5%;
}
.Title {
    color: black;
}

.Selected {
    border: 2px solid orange;
}

.Column {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 20px;
    margin-bottom: 10px;
}
