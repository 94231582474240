.Root {
    position: fixed;
    top: 50%;
    left: 52%;
    z-index: 100000;
    margin-top: -50px;
    margin-left: -50px;
    height: 100px !important;
    width: 100px !important;
    outline: 0px;
}
