.Photo {
    height: 30%;
    width: 30%;
}

.GalleryContainer {
    > div:nth-child(1) {
        max-width: 860px;
        margin: 0 auto;
    }
    @media only screen and (max-width:1000px) {
        > div:nth-child(1) {
            width: 760px;
        }    
    }
    @media only screen and (max-width:900px) {
        > div:nth-child(1) {
            width: 640px;
        }    
    }
    @media only screen and (max-width:800px) {
        > div:nth-child(1) {
            width: 600px;
        }    
    }
    @media only screen and (max-width:700px) {
        > div:nth-child(1) {
            width: 500px;
        }    
    }
    @media only screen and (max-width:600px) {
        > div:nth-child(1) {
            width: 500px;
        }    
    }
    @media only screen and (max-width:460px) {
        > div:nth-child(1) {
            width: 380px;
            max-width: 300px;
        }    
    }
    @media only screen and (max-width:360px) {
        > div:nth-child(1) {
            width: 280px;
            max-width: 250px;
        }    
    }
}