@import '../vars';


.Root {
    width: fit-content;
    overflow-x: auto;

    @media only screen and (max-width: 1140px), (min-device-width: 768px) and (max-device-width: 1024px) {
        /* Force table to not be like tables anymore */
        table, thead, tbody, th, td, tr {
            display: block;
        }

        tbody > tr:nth-child(1) {
            display: inline-table;
            width: 100%;
        }
        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        tr {
            border: 1px solid #ccc;
            padding-left: 10px;
            padding-top: 10px;
            vertical-align: bottom;
        }

        td {
            /* Behave  like a "row" */
            border: none;
            border-bottom: 1px solid #eee;
            position: relative;
            padding-left: 50%;
        }

        td:before {
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            top: 6px;
            left: 6px;
            width: 45%;
            white-space: nowrap;
            vertical-align: bottom;
        }
        /*
        Label the data
        */
        td:nth-of-type(1):before {
            content: "Broj računa";
        }

        td:nth-of-type(2):before {
            content: "Klijent";
        }

        td:nth-of-type(3):before {
            content: "OIB";
        }

        td:nth-of-type(4):before {
            content: "PDF";
        }

        td:nth-of-type(5):before {
            content: "Datum izdavanja";
        }

        td:nth-of-type(6):before {
            content: "Datum dospijeća";
        }
    }
}



.dropdown {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    margin-top: 30px;
    max-width: 200px;
}
