.MarginTop{
    margin-top:-15px;
}

.TextValidator {
    width: 100%;
    padding: 5px;
}

.TextValidatorFirst {
    width: 100%;
    padding: 5px;
    margin-top: 50px;
}

.Dropdown {
    padding-bottom: 2px;
    padding-top: 2px;
}

.selectSeller {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    max-width: 800px;
}