.Button {
    margin-top: 30px;
    margin-bottom: 10px;
}

.Modal {
    overflow: auto;
    display: flex;
    align-content: center;
}

.RootModal {
    background: white;
    margin: auto;
    max-width: 665px;
    outline: none;
    padding: 22px;
}

.Question {
    margin-top: 10px;
}

.MainText {
    margin-top: 10px;
    margin-bottom: 10px;
}

.Text {
    margin-top: 10px;
}
