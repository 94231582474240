.ForgotPass {
    width: 127px;
    height: 14px;
    font-size: 11px;
    font-weight: 600;
    text-align: center;
    color: #666666;
}

.SubmitButton {
    width: 100%;
}

.PaperRoot {
    background: red;
}

.EmailDelivered {
    color: white;
    text-align: center;
    position: absolute;
    margin: 0 auto;
    width: 100%;
    right: 0;
}