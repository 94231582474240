.card {
    display: flex;
    height: 100%;
  }
.details {
    display: flex;
    flex-direction: column;
  }
.content {
    flex: 1 0 auto;
  }
.cover {
    width: 159px;
    background-color: #49B824;
  }
.controls {
    display: flex;
    align-items: center;
  }

.lastMonthSold {
    font-size: 12px;
    padding-top: 8px;
    padding-left: 4px;
    font-style: italic;
  }