.Control {
    margin-bottom: 15px;
}

.Menu {
    position: absolute;
    z-index: 1;
    margin-top: -8px;
    left: 0;
    right: 0;
}

.Input {
    display: flex;
    padding: 0;
    height: auto;
}

.Placeholder {
    position: absolute;
    left: 2;
    bottom: 6;
    font-size: 16px;
}

.Value {
    font-size: 16px;
}

.Container {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    align-items: center;
    overflow: hidden;
}