@import '../../vars';

.container {
  width: 100%;
  padding: 20px;
}

.formContainer {
  padding: 10px;
}
.ListItem {
  height: auto;
}

.Select {
  width: 270px;
  min-width: 230px;
  margin-top: 15px;
  margin-bottom: -15px;
}

.selectSeller {
  max-width: 800px;
}

.textarea {
  max-width: 800px;
  width: 800px;
  margin-top: 30px;
}

.addOneMore {
  float: right;
  margin-top: 10px;
}

.btnWrapper {
  max-width: 800px;
  width: 800px;
}
