@import "./../../vars";

.Root {
    background: $color-primary-foreground;
    margin:auto;
    max-width:865px;
    outline: none;
    padding: 15px;
    margin-bottom: 15px;
}

.Title h2 {
    padding: 0px 0px 0px 50px;
}

.ButtonContainer {
    padding: 0px 10px 0px 10px;
}

.GridContainer  {
    padding-left: 20px;
}

.GridItem{
    padding-left: 20px;
}

.ButtonGrid {
    padding: 10px;
}

.Button {
    padding: 5px 15px 5px 15px;
}

.ContactContainer {
    padding: 0px 0px 0px 15px;
}

.ContactGrid {
    padding: 0px 0px 10px 45px;
}

.ContactP {
    padding: 0px 0px 0px 15px;
}

.NoMargin {
    margin: 0px !important;
}