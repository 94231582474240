@import '../vars';

.Root {
    width: fit-content;
    overflow-x: auto;

    @media only screen and (max-width: 1140px), (min-device-width: 768px) and (max-device-width: 1024px) {
        /* Force table to not be like tables anymore */
        table, thead, tbody, th, td, tr {
            display: block;
        }

        tbody > tr:nth-child(1) {
            display: inline-table;
            width: 100%;
        }
        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        tr {
            border: 1px solid #ccc;
            padding-left: 10px;
            padding-top: 10px;
            vertical-align: bottom;
        }

        td {
            /* Behave  like a "row" */
            border: none;
            border-bottom: 1px solid #eee;
            position: relative;
            padding-left: 50%;
        }

        td:before {
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            top: 6px;
            left: 6px;
            width: 45%;
            white-space: nowrap;
            vertical-align: bottom;
        }
        /*
        Label the data
        */
        td:nth-of-type(1):before {
            content: "Trgovac";
        }

        td:nth-of-type(2):before {
            content: "Br. jamstva";
        }

        td:nth-of-type(3):before {
            content: "Šasija";
        }

        td:nth-of-type(4):before {
            content: "Marka";
        }

        td:nth-of-type(5):before {
            content: "Model";
        }

        td:nth-of-type(6):before {
            content: "Kilometri";
        }

        td:nth-of-type(7):before {
            content: "Status auta";
        }

        td:nth-of-type(8):before {
            content: "Status jamstva";
        }

        td:nth-of-type(9):before {
            content: "Akcija jamstva";
        }

        td:nth-of-type(10):before {
            content: "Akcija štete";
        }

        td:nth-of-type(11):before {
            content: "Prodaja";
        }

        td:nth-of-type(12):before {
            content: "Pregled šteta";
        }
    }
}
// auti
.Available {
    background-color: $color-sell !important;
    color: white !important;

    &:hover {
        background-color: $color-success-hover;
    }
}

.Sold {
    background-color: $color-success !important;
    color: white !important;

    &:hover {
        background-color: $color-success-hover;
    }
}

// warranty statusi
.Active {
    background-color: $color-active !important;
    color: white !important;

    &:hover {
        background-color: $color-active-hover;
    }
}

.Approved {
    background-color: $color-success !important;
    color: white !important;

    &:hover {
        background-color: $color-success-hover;
    }
}

.Declined {
    background-color: $color-error !important;
    color: white !important;

    &:hover {
        background-color: $color-error-hover;
    }
}

.InProcess {
    background-color: $color-info !important;
    color: white !important;

    &:hover {
        background-color: $color-error-hover;
    }
}


.Inactive {
    background-color: $color-inactive !important;
    color: white !important;

    &:hover {
        background-color: $color-error-hover;
    }
}

.Submitted {
    background-color: $color-submitted !important;
    color: white !important;

    &:hover {
        background-color: $color-error-hover;
    }
}

.Modal {
    overflow: auto;
    display: flex;
    align-content: center;
}

.buyerFormReadonly {
    margin-top: 20px;
    opacity: 0.5;
    pointer-events: none;
}

.RootModal {
    background: $color-primary-foreground;
    margin: auto;
    max-width: 665px;
    outline: none;
    padding: 22px;
    overflow-y: scroll;
}
