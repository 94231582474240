MailButton.ListItem {
    height: auto; 
}
.Button {
    float: right;
}
.Title h3{
    height: auto !important;
    padding-left: 30px;
    font-size: 1.5em;
}

.GridItem {
    padding-top: 5px;
    padding-bottom: 15px;
    padding-left: 25px;
    padding-right: 25px;
}

.SaveButton {
    width: 80%;
    margin-top: 20px;
    margin-bottom: 30px;
    margin-left: 50px;
    margin-right: 50px;
}

.selectSeller {
    padding-left: 30px;
    padding-right: 30px;
    max-width: 800px;
}

.Modal {
    overflow: auto;
    display: flex;
    align-content: center;
}

.RootModal {
    background: white;
    margin: auto;
    max-width: 665px;
    outline: none;
    padding: 22px;
}

.ChangeModalButton {
    width: 30%;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;
}

.MailButton {
    margin-top: 10px;
}