.Background {
    height: 100%;
    width: 100%;
}

.login {
    background:linear-gradient(0deg,rgba(0,0,0,0.3),rgba(0,0,0,0.3)), url('../../resources/img/login.jpg');
    overflow: auto;
    background-size: cover;
    height: 100%;
    padding: 20px;
}