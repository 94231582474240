$color-primary: #34B6E4;
$color-hover: #1B9CCA;
$color-active: #13779B;
$color-primary-foreground: #FFFFFF;
$color-link: #35BEEF;
$color-link-hover: #142B4C;
$color-foreground: #000000;
$color-secondary:#6C757D;
$color-layout-background: #E5E5E5;
$background-color-header: #1B2E4C;
$input-border-radius: 3px;
$input-background: $color-primary-foreground;
$input-background-active: #F5F5F5;
$search-input-placeholder-foreground: #9B9B9B;
$input-foreground: $color-foreground;
$input-border-color: #C7CED5;
$main-heading-text: #042031;
$color-text-light: $color-layout-background;
$color-active: #0055a7;
$color-active-hover: #0055a7;
$color-success: #379a0b;
$color-success-hover: #379a0b;
$color-error: #BA000D;
$color-error-hover: #9A0007;
$color-info: #FFA726;
$color-submitted: #6C757D;
$color-inactive: #CED4DA;
$color-sell: #15669c;

$color-active-hover: #13799D;

$footer-link-default-color: #0569A6;


$input-border-color-active: $color-primary;