
.Modal {
    overflow: auto;
    display: flex;
    align-content: center;
}

.RootModal {
    background: white;
    margin: auto;
    max-width: 665px;
    outline: none;
    padding: 22px;
}

.Form {
    box-sizing: unset !important;
}

.Button {
    margin: 20px;
}
