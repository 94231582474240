@import '../../vars';

.Root {
    width: 100%;
    background: $color-primary-foreground;
    margin:auto;
   // max-width:800px;
    max-height: 1000px;
    outline: none;
    padding: 10px 0px;
    padding: 15px;
    float: left;
}

.GridRoot {
    display: flex;
    flex-wrap: wrap;
}

.GridItem {
    padding: 2px;
    width: 100%;
}

.GridItem input {
    min-width: 270px;

}

.GridButton {
    margin-right: 20px;
    margin-top: 35px;
    margin-bottom: 15px;
    padding: 20px;
}

.OdbioUgovorButton {
    margin-right: 30px;
    margin-top: 35px;
    margin-bottom: 15px;
    padding: 20px;
}

.PrikaziUgovorButton {
    margin-right: 120px;
    margin-top: 35px;
    margin-bottom: 15px;
    padding: 20px;
}

.Select {
    width:270px;
    min-width: 230px;
    margin-top: 15px;
    margin-bottom: -15px;
}

.Modal {
    overflow: auto;
    display: flex;
    align-content: center;
}

.RootModal {
    background: white;
    margin: auto;
    max-width: 665px;
    outline: none;
    padding: 22px;
}