.Button {
    margin-top: 20px;
}

.Modal {
    overflow: auto;
    display: flex;
    align-content: center;
}

.RootModal {
    background: white;
    margin: auto;
    max-width: 665px;
    outline: none;
    padding: 22px;
}

.Question {
    margin-top: 50px;
}

.MainText {
    margin-bottom: 20px;
}