.selectSeller {
    display: inline-table;
    width: 240px;
    margin: 15px;
    padding-top: 12px;
    z-index: 999;
}

.search { 
    margin: 25px; 
}

.invoice {
    float: right;
    margin: 25px; 
}

@media only screen and (max-width: 600px) {
    .selectSeller {
      margin: 0px;
    }

    .search {
        margin: 0px;
        padding-top: 10px;
        width: 240px;
        margin-top: 25px;
    }
    .invoice {
        margin: 0px;
        padding-top: 10px;
        width: 240px;
        margin-top: 25px;
        float: none;
    }
  }

  @media only screen and (max-width: 600px) {
    .warrantyDetailsTable {
      /* Force table to not be like tables anymore */
      table, thead, tbody, th, td, tr { 
          display: block !important; 
      }
      
      /* Hide table headers (but not display: none;, for accessibility) */
      thead tr { 
          position: absolute;
          top: -9999px;
          left: -9999px;
      }
      
      tr {
           border: 1px solid #ccc;
           padding-left: 10px;
           padding-top:10px;
           vertical-align: bottom;
       }
      
      td { 
          /* Behave  like a "row" */
          border: none;
          border-bottom: 1px solid #eee; 
          position: relative;
          padding-left: 50%; 
      }
      
      td:before { 
          /* Now like a table header */
          position: absolute;
          /* Top/left values mimic padding */
          top: 6px;
          left: 6px;
          width: 45%; 
          white-space: nowrap;
          vertical-align: bottom;
      }
      /*
      Label the data
      */
      td:nth-of-type(1):before { content: "Broj šasije"; }
      td:nth-of-type(2):before { content: "Šifra jamstva"; }
      td:nth-of-type(3):before { content: "Status jamstva"; }
      td:nth-of-type(4):before { content: "Auto"; }
      td:nth-of-type(5):before { content: "Vrijedi od"; }
      td:nth-of-type(6):before { content: "Polica"; }
      td:nth-of-type(7):before { content: "Cijena bez PDV"; }
      td:nth-of-type(8):before { content: "Pravo na jamstvo"; }
  }
}