@import '../vars';

.Modal {
    overflow: auto !important;
}
.Stepper {
    position: relative !important;
    
    @media only screen and (min-width:960px) {
        min-width: 100%;
        min-height: 400px;
       // padding-bottom: 50px;
    }
    @media only screen and(max-width:960px) {
        width: 100%;
    }
}
.StepperPagination {
    position: absolute;
    top: 0px;
    margin-bottom: 10px;

    width: 100%;

    > div {
        background: $color-primary-foreground;
    }
    @media only screen and (max-width:960px) {
        display: none;
    }
}

.Content {
    @media only screen and (min-width:960px) {
        padding-top: 20px;
    }
}

.MobileStepperPagination {
    margin-bottom: 0px;
    @media only screen and (min-width:960px) {
        display: none;
    }
}