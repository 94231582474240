
@import '../vars';

.footer {
    background-color: transparent;
    height: 40px;
    bottom:-5px;
    width:100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: fixed;
    z-index: -1;
    margin-top: 35px;
}

.links {
    color: $color-primary-foreground;
    padding: 7px;
}

.footerLinks {
    padding: 10px;
}