@import '../vars';

.link {
    font-size: 0.75em;
    color: $footer-link-default-color;
    text-decoration: none;
    padding: 0.625em;

    &:hover {
        color: $color-link-hover;
    }

    &:focus, &:active {
        color: $color-link;
        border: 1px solid $color-link;
    }
}