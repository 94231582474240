@import '../vars';

.Root {
    width: 100%;
    overflow-x: auto;

        @media 
    only screen and (max-width: 1067px),
    (min-device-width: 768px) and (max-device-width: 1024px)  {

        /* Force table to not be like tables anymore */
        table, thead, tbody, th, td, tr { 
            display: block; 
        }
        
        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr { 
            position: absolute;
            top: -9999px;
            left: -9999px;
        }
        
        tr {
             border: 1px solid #ccc;
             padding-left: 10px;
             padding-top:10px;
             vertical-align: bottom;
         }
        
        td { 
            /* Behave  like a "row" */
            border: none;
            border-bottom: 1px solid #eee; 
            position: relative;
            padding-left: 50%; 
        }
        
        td:before { 
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            top: 6px;
            left: 6px;
            width: 45%; 
            white-space: nowrap;
            vertical-align: bottom;
        }
        /*
        Label the data
        */
        td:nth-of-type(1):before { content: "Broj šasije"; }
        td:nth-of-type(2):before { content: "Trgovac"; }
        td:nth-of-type(3):before { content: "Marka auta"; }
        td:nth-of-type(4):before { content: "Model auta"; }
        td:nth-of-type(5):before { content: "Status štete"; }
        td:nth-of-type(6):before { content: "Troškovi"; }
        td:nth-of-type(7):before { content: "Datum prijave"; }
        td:nth-of-type(8):before { content: "Akcija štete"; }
    }
}


.InfoButton {
    background-color: $color-info;
  }

  
.Approved {
    color: $color-success;
  }
  
  .Declined{
    color: $color-error;
  }

  .Reported {
    color: $color-info;
  }

  // damage action

  .Edit {
      background-color: $color-info
  }

  .View {
    background-color: $color-success;
  }