@import '../../vars';

.Root {
    background: $color-primary-foreground;
    margin: auto;
    max-width: 665px;
    outline: none;
    padding: 15px;
}

.Form {
    box-sizing: unset !important;
}

.OuterGrid {
    padding-top: 7px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding-bottom: 10px !important;
}

.Grid {
    padding: 8px;
}

.Button {
    padding: 7px 20px 7px 20px;
    margin-left: 70px;
    margin-right: 70px;
    margin-top: 10px;
}



.BuyerInputs {
    width: 100%;
}

.Modal {
    overflow: auto;
    display: flex;
    align-content: center;
}

.RootModal {
    background: white;
    margin: auto;
    max-width: 665px;
    outline: none;
    padding: 22px;
}
